import { Box } from "@mui/material"

export function Usuarios() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "100%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Usuários</h1>
        <p>Painéis do Usuário</p>
        <p>Você encontrará as seguintes opções no painel do usuário:</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FPYfDLyIgjjOeC9B4rKMi%252Fimage.png%3Falt%3Dmedia%26token%3Dd51bc09a-18e9-4d5b-b330-c0eb63b37e1a&width=768&dpr=1&quality=100&sign=a5302023&sv=1"/>

      </Box>

    </Box>
  );
}