import { Box } from "@mui/material"
import { Link } from "react-router-dom";

export function DuvidasFrequentes() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "660%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#ffffff"
        }}>

        <h1>Dúvidas Frequentes</h1>
        <p>Aqui você encontrará as perguntas frequentes sobre a plataforma e suas funcionalidades</p>
        <br />
        <h2>Como visualizo a versão atual do meu sistema?</h2>
        <p>Para visualizar a versão do seu sistema clique no botão que encontra-se no canto superior direito da plataforma.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252Fe4nDlnsD4DKmrywvoH8f%252Fimage.png%3Falt%3Dmedia%26token%3Da8959f1d-eaa2-4379-b3f0-570ece024daf&width=768&dpr=1&quality=100&sign=c132f274&sv=1" />
        <br />
        <h2>Como visualizar os atendimentos?</h2>
        <p>Para visualizar os atendimentos navegue até a Lista de Atendimentos no menu do usuário. </p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/atendimento"> Atendimento</Link> ou
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/admnistrador/painel-de-atendimento"> Painel de Atendimento</Link>.
        </p>
        <br />
        <h2>Como transferir atendimentos?</h2>
        <p>Para transferir um atendimentos utilize o botão que existe dentro do atendimento, escolha a Fila de Destino e o Usuário Destino. </p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/atendimentos-gerais"> Atendimentos Gerais</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FDbywGouJhQORPbKjGbig%252Fimage.png%3Falt%3Dmedia%26token%3D89fa923e-14b9-41a9-884f-36041d2b69e1&width=768&dpr=1&quality=100&sign=7635eea9&sv=1" />
        <br />
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FcOVfETgGE5KBpGOO1Seu%252Fimage.png%3Falt%3Dmedia%26token%3D31a450c3-a779-4709-9a2f-beb1d631ee55&width=768&dpr=1&quality=100&sign=9da42492&sv=1" />
        <br />
        <h2>Como posso enviar arquivos e áudios?</h2>
        <p>Para enviar áudios ou arquivos utilize os botões na tela de atendimento.</p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/atendimentos-gerais"> Atendimento</Link>
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FVUtjZLjH8ODEntYzaa7N%252Fimage.png%3Falt%3Dmedia%26token%3Db04c17b5-f63d-40a2-bcec-a611268ef1d4&width=768&dpr=1&quality=100&sign=a0a9a880&sv=1" />
        <br />
        <h2>Como posso importar meus contatos?</h2>
        <p>Para importar os contatos, você deve utilizar o botão sincronizar dentro da tela de Contatos, no menu do Usuário.</p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/administrador/contatos">Contato</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FeLGTGwUZiY4mC6ggKgjm%252Fimage.png%3Falt%3Dmedia%26token%3D56d634e7-11d4-44a9-84d0-2a3da9cd4718&width=768&dpr=1&quality=100&sign=25e43f6c&sv=1" />
        <br />
        <h2>Como posso criar respostas rápidas?</h2>
        <p>Para cadastras respostas rápidas você deve navegar até a opções Mensagens Rápida no menu do Administrador.</p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/administrador/mensagens-rapidas"> Mensagens Rápidas</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FnbE85jJBctvu27parNld%252Fimage.png%3Falt%3Dmedia%26token%3D67688e4f-2ae5-4ab1-a0e2-2464abadb99a&width=768&dpr=1&quality=100&sign=d5972779&sv=1" />
        <br />
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FsuN1BCDuzYTlmEOnshVj%252Fimage.png%3Falt%3Dmedia%26token%3D46fee3a8-f879-43f2-8438-b5daeebf73a1&width=768&dpr=1&quality=100&sign=79135665&sv=1" />
        <br />
        <h2>Como realizo envios em massa?</h2>
        <p>Para realizar disparos em massa você deve navegar até a opção disparos em massa no menu do Usuário.</p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/disparo-em-massa"> Disparo em Massa</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FinqLYMACVDhpBmwsmL6j%252Fimage.png%3Falt%3Dmedia%26token%3Ddb12db76-ea9c-40f2-a34f-2d0a53aca530&width=768&dpr=1&quality=100&sign=90d2789f&sv=1" />
        <br />
        <h2>Qual o limite de envio de mensagens?</h2>
        <p>Para definir limites de conexões e usuários você deve navegar até a opção Tenants no menu do Super Admin. Caso contrário, eles serão ilimitados.</p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/super-admin"> Multi Tenants</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FRdOwZsaqG2WMF2xrkw8r%252Fimage.png%3Falt%3Dmedia%26token%3Dfdb1b48c-4b38-4639-a046-a11be46a9bdf&width=768&dpr=1&quality=100&sign=b9ef6a97&sv=1" />
        <br />
        <h2>Como posso gerenciar grupos?</h2>
        <p>Para gerenciar grupos em massa você deve navegar até a opção Grupos no menu do Usuário.</p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/grupos"> Grupos</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FrmkF9GzDfTlz5GS8LlTC%252Fimage.png%3Falt%3Dmedia%26token%3D5020adff-410e-43cf-881a-e3bec9e985fd&width=768&dpr=1&quality=100&sign=8289e7eb&sv=1" />
        <br />
        <h2>Como eu crio tarefas?</h2>
        <p>Para criar tarefas você deve navegar até a opção Tarefas no menu do Usuário.</p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/tarefas"> Tarefas</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252Fcwz4HaltmfMq7yrpEBAL%252Fimage.png%3Falt%3Dmedia%26token%3D0b7ad57a-b893-4fc7-8650-5f2d80c62a8f&width=768&dpr=1&quality=100&sign=fb089ab4&sv=1" />
        <br />
        <h2>Como eu uso o CRM (Kanban)</h2>
        <p>Para usar o CRM você deve navegar até a opção Kanban no menu do Usuário.</p>
        <p>Mais informações em
          <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/kanban"> Kanban</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FG94MfFVtyC8m9v27ugAt%252Fimage.png%3Falt%3Dmedia%26token%3Da729cf93-f2ba-4bff-841f-1c615db09f43&width=768&dpr=1&quality=100&sign=658a0b82&sv=1" />
        <br />
        <h2>Como eu posso acessar a tela de Login?</h2>
        <p>Para acessar a tela de cadastro de clientes com planos você deve seguir para o endereço.</p>
        <p><Link style={{ color: "#9bc1db", textDecoration: "none" }} to="https://app.toapply.com.br/#/login">app.toapply.com.br/#/login</Link></p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FFSFcZ6r6PS5VNn5GZWOH%252Fimage.png%3Falt%3Dmedia%26token%3D71ce5e6f-3acb-44fc-964a-1eeedfaea2df&width=768&dpr=1&quality=100&sign=ea0ab34a&sv=1" />
        <br />
        <h2>Como eu posso acessar a tela de Signup?</h2>
        <p>Para acessar a tela de cadastro de clientes com planos você deve seguir para o endereço.</p>
        <p><Link style={{ color: "#9bc1db", textDecoration: "none" }} to="https://app.toapply.com.br/#/signup">app.toapply.com.br/#/signup</Link></p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FKuy9ZLuD7lwJ6QZBh0Is%252Fimage.png%3Falt%3Dmedia%26token%3D42701f95-3ee8-46c6-84df-b62daef1a789&width=768&dpr=1&quality=100&sign=765a640f&sv=1" />

      </Box>

    </Box>
  );
}