import { Box } from "@mui/material"

export function Dashboard() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "220%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Dashboard</h1>
        <p>Painel de Controle</p>
        <p>O painel de controle é a sua tela inicial, a primeira visão quando efetuar o login. Seu ícone é correspondente à home da plataforma.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2Fcontent.gitbook.com%2Fcontent%2FM5B652UsmGn3Q7h9Du1t%2Fblobs%2FSJUSK3varQujdRWGL76n%2Fimage.png&width=300&dpr=1&quality=100&sign=4e503a81&sv=1"/>
        <p>No Dashboard você verá a seguinte tela:</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FNd01VEO5Yw9eGUPs3r7T%252Fimage.png%3Falt%3Dmedia%26token%3D46ac7720-9d27-47b3-bda7-1f540eb0a72b&width=768&dpr=1&quality=100&sign=6c6f6951&sv=1"/>
        <p>Nessa primeira visão, você tem a opções de filtro que se alteram de acordo com o período inserido, setor selecionado, atendimentos privados ou de grupos.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2Fcontent.gitbook.com%2Fcontent%2FM5B652UsmGn3Q7h9Du1t%2Fblobs%2FxaSqWO1PBuxw7cNtLOsV%2Fimage.png&width=768&dpr=1&quality=100&sign=aabd1c52&sv=1"/>
        <p>Logo abaixo você pode ver os dados dos atendimentos organizados em dois gráficos: Atendimentos por Canal (que podem ser WhatsApp, WABA e Telegram) e Atendimentos por Filas (correspondente aos setores/departamentos cadastrados).</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FbHktjCeHjscyhiaI6bGt%252Fimage.png%3Falt%3Dmedia%26token%3D494642a1-4d99-434c-bd42-9f35a5695567&width=768&dpr=1&quality=100&sign=9d3a24e5&sv=1"/>
        <p>Em Evolução por Canal, você vê a porcentagem dos atendimentos realizados no WhatsApp, WABA e Telegram ao longo do tempo.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FVLUgSKVbd7zrJEb5smlv%252Fimage.png%3Falt%3Dmedia%26token%3D5d840ed8-a7e4-4b5b-b052-4dbac436be03&width=768&dpr=1&quality=100&sign=b1b306f6&sv=1"/>
        <p>Aqui em Evolução Atendimentos é possível ver o quantitativo de atendimentos ao longo do tempo.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FO1joww0Oev5bZWwz7JKy%252Fimage.png%3Falt%3Dmedia%26token%3D42fe425f-3fde-4384-b924-b023933800c1&width=768&dpr=1&quality=100&sign=9dbda684&sv=1"/>
        <p>Por último, temos o Desempenho da Equipe.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FLRUBWc71S3HN9qlDXB0t%252Fimage.png%3Falt%3Dmedia%26token%3Dac3f1be9-c847-4dbd-9a65-c2c61c5d3c82&width=768&dpr=1&quality=100&sign=8179f6fe&sv=1"/>
        <p>Aqui você verá os seguintes dados:</p>
        <img src="tabela-dashboard.png"/>


      </Box>

    </Box>
  );
}