import { Box } from "@mui/material"

export function Supervisor() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "120%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Supervisor</h1>
        <p>Painéis do Supervisor</p>
        <p>Você encontrará as seguintes opções no painel do usuário:</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FdjT1dUqPqJuIiIZkoDBw%252Fimage.png%3Falt%3Dmedia%26token%3D815a0085-9525-40c5-9c1c-a8f5f0f4e8b1&width=768&dpr=1&quality=100&sign=18e36813&sv=1"/>
        
      </Box>

    </Box>
  );
}