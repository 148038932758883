import { AppBar, Container, Toolbar } from '@mui/material';

function Navbar() {
    return (
        <Container>
            <AppBar position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    height: "10%",
                    justifyContent: "center",
                    backgroundColor: "#202a45"
                }}
            >

                <Toolbar>

                    <img src="logo.png" style={{width: '10%'}}/>

                </Toolbar>

            </AppBar>
        </Container>
    );
}

export default Navbar;