import { Box } from "@mui/material"

export function RequisitosDeUso() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "100%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Requisitos de Uso</h1>
        <p>Requisitos para Uso da Plataforma</p>
        <p>● Utilize a Plataforma WhatsApp e a Plataforma Telegram, de acordo com os Termos e Condições de Uso próprios dessas plataformas; e</p>
        <p>● Tenha idade igual ou superior a 13 (treze) anos.</p>
        <br/>
        <p>Recomendamos que o sistema operacional e o navegador do Usuário estejam atualizados para aproveitar as otimizações de desempenho e segurança do Z-PRO, bem como que satisfaçam, pelo menos, os seguintes requisitos:</p>
        <p>● Memória (RAM) de 8GB ou mais</p>
        <p>● Conexão de internet rápida e estável</p>
        <br/>
        <p>A VPS onde o sistema será instalado deverá satisfazer, os seguintes requisitos mínimos:</p>
        <p>● Memória (RAM) de 16GB ou mais</p>
        <p>● 4vcpus ou mais</p>
        <p>● Latência de 50ms ou menos</p>
        <p>● Ubuntu 20.04 ou 22.04 LTS sem Painel</p>
        <p>● Arquitetura AMD</p>
        <br/>
        <p>Além disso, é crucial manter o sistema operacional e o navegador atualizados para evitar riscos de segurança da informação e privacidade, bem como para garantir uma melhor eficácia e funcionamento do Z-PRO.</p>
        <p>As versões suportadas da Plataforma WhatsApp são as seguintes:</p>
        <p>● API WhatsApp Business Account v19.0 ou superior</p>
        <p>● Até a versão Whatsapp Web v2.24xx.x</p>

      </Box>

    </Box>
  );
}