import { Box } from "@mui/material"

export function Administrador() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "115%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Painéis do Admin</h1>
        <p>Plataforma de Multiatendimento</p>
        <p>Você encontrará as seguintes opções no painel do usuário:</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FrnDP1B0dqK2y4Y4ez3OS%252Fimage.png%3Falt%3Dmedia%26token%3D7a5850a9-7c3a-47f7-9e80-9b8fe1358ffd&width=768&dpr=1&quality=100&sign=5dccf1ef&sv=1"/>
        
      </Box>

    </Box>
  );
}