import { Box } from "@mui/material"
import { Link } from "react-router-dom";

export function AtendimentosGerais() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "945%",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Atendimentos Gerais</h1>
        <p>Painel de Atendimentos</p>
        <p>Vamos iniciar um overview de toda a área do atendimento. Para começar, vamos falar do botão no canto superior esquerdo. Aqui ficará o nome do usuário e ao clicar aparecerá as seguintes opções: Perfil e Sair</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FUvW5ycGO9wfvHWiXCHR3%252Fimage.png%3Falt%3Dmedia%26token%3D956247af-3c6c-4e3b-abf5-4c9060a5816d&width=768&dpr=1&quality=100&sign=d0d1b536&sv=1"/>
        <p>Clicando em Perfil, abrirá um pop up para editar as informações do usuário. Observe o exemplo:</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FPsGV4w7eD1UnZV9iJklG%252Fimage.png%3Falt%3Dmedia%26token%3D7642c670-90c3-440d-9318-19f17b51a4b2&width=768&dpr=1&quality=100&sign=f1453c86&sv=1"/>
        <p>E clicando em Sair, realizará o logout sem precisar retornar ao menu. Aliás, para fazer esse retorno, basta clicar no botão de 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/dashboard"> Home</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252F9mE2HxsEOH8NrGGGZiXI%252Fimage.png%3Falt%3Dmedia%26token%3Dc76d1db7-acfd-4b2d-a766-60265e80b5fd&width=768&dpr=1&quality=100&sign=852bb99b&sv=1"/>
        <p>Clicando no botão abaixo, você será direcionado ao
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/chat/interno"> Chat Interno</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252F04QLLqIHxU83IYP4NYOO%252Fimage.png%3Falt%3Dmedia%26token%3Dbb2a25d1-37dc-4528-aad3-7284c6243c3d&width=768&dpr=1&quality=100&sign=c92b59c6&sv=1"/>
        <p>Clicando no botão abaixo, você poderá criar um 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/tarefas"> Tarefa Nova</Link>.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252Fob9AfXA97h4OnrIiiMNv%252Fimage.png%3Falt%3Dmedia%26token%3D5dd85431-3a6a-468b-bc97-e4b81f8609a8&width=768&dpr=1&quality=100&sign=e5840e82&sv=1"/>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FJTrAGSVQHjiYB477ASxG%252Fimage.png%3Falt%3Dmedia%26token%3Dd476b877-b539-49be-a708-26cdf7eee798&width=768&dpr=1&quality=100&sign=948cc55d&sv=1"/>
        <p>Mais abaixo você tem:</p>
        <img src="tabela-atendimentos-gerais.png"/>
        <p>A depender de qual dos botões tenha sido selecionado (aberto, pendente ou fechado) você visualizará os contatos correspondentes.</p>
        <p>Já na segunda tela, quando uma conversa é selecionada, a visão é essa:</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FcIof0CNeT9ArMgUN90wD%252Fimage.png%3Falt%3Dmedia%26token%3D96550b6e-3e36-4fc6-afff-6c1aff396032&width=768&dpr=1&quality=100&sign=b9fd1112&sv=1"/>
        <p>No canto superior esquerdo é possível ver a foto, nome do cliente, a quem foi atribuído o atendimento e o ticket do atendimento.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252Fo4SeYVd4X9nKAr5kW6Lk%252Fimage.png%3Falt%3Dmedia%26token%3D3f89cbf2-f695-416f-9348-bb99adfce995&width=768&dpr=1&quality=100&sign=d64c05ec&sv=1"/>
        <p>À direita estão alguns botões de ação, que facilitam o atendimento. Conheça cada um deles:</p>
        <img src="tabela-atendimentos-gerais-2.png"/>
        <p>Na parte inferior da tela é onde seus usuários vão se comunicar com o cliente. À esquerda se encontram os botões de: envio de arquivo, emoji, link para vídeo chamada e figurinhas.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252Fw53RmrbJ7SMJCP16m6Xb%252Fimage.png%3Falt%3Dmedia%26token%3D5f3c3cd7-1f6e-48f5-aff8-dae22c175584&width=768&dpr=1&quality=100&sign=22879072&sv=1"/>
        <p>Aqui também é possível ativar e desativar a assinatura que identifica seus colaboradores no atendimento.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FIEZnzsupzAs1W05Xl2bI%252Fimage.png%3Falt%3Dmedia%26token%3Dc07c2f59-1d00-428a-bbd4-f9fa0a4abda7&width=768&dpr=1&quality=100&sign=3de35940&sv=1"/>
        <p>Ao lado está o campo de escrita das mensagens, o atalho de mensagens rápidas e botão para envio de áudio.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FGJDv6AmFnb9bE9JqDwET%252Fimage.png%3Falt%3Dmedia%26token%3D3312ddfb-3aef-4823-8b2a-9049d329e59e&width=768&dpr=1&quality=100&sign=9e88bfa8&sv=1"/>
        <p>No canto direito está o menu lateral com os dados do cliente. No primeiro campo você visualiza foto, nome, número e possui a opção de editar o contato.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FViAn065sGjCPUUefbCQF%252Fimage.png%3Falt%3Dmedia%26token%3D95276fbb-d88f-4def-bd60-ed98b37f4174&width=768&dpr=1&quality=100&sign=26a98090&sv=1"/>
        <p>Além disso você encontra as opções de:</p>
        <p><strong>SMS:</strong> se ativo a API da Comtele em 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="admnistrador/conf-sms"> Configurações</Link>, será possível visualizar o botão para envio de SMS.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FAgJCgFwdgndvHkP60c9O%252Fimage.png%3Falt%3Dmedia%26token%3Da9c587c3-254d-4d89-bb8a-22ae8e633178&width=768&dpr=1&quality=100&sign=82ace3ae&sv=1"/>
        <p><strong>Logs e Marcar como Lido/Não Lido:</strong> com a linha do tempo de acessos que os usuários fizeram a esse atendimento. Ao lado dos logs você também pode marcar o atendimento como lido ou não lido.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FpjtbYTCIdcGc6zCaetM6%252Fimage.png%3Falt%3Dmedia%26token%3Dacb7a508-a319-425a-a541-1da296621a8d&width=768&dpr=1&quality=100&sign=6ff7828&sv=1"/>
        <br/>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FqNg1jOAcQbKLP1qH9noS%252Fimage.png%3Falt%3Dmedia%26token%3D9bdf1ef2-a01e-4a38-8886-c5f9a44771d5&width=768&dpr=1&quality=100&sign=24d6817c&sv=1"/>
        <p><strong>Sincronizar Histórico (Apenas para WhatsApp WEB):</strong> onde é possível sincronizar mensagens antigas do dispositivo de celular, informando o limite de mensagens que serão importadas na plataforma. Somente mensagens que não estão na plataforma, serão importadas.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FoLV12jLHT1Wl5V65egOJ%252Fimage.png%3Falt%3Dmedia%26token%3Defadfc46-5a6b-45ec-9e46-9f4931af84a7&width=768&dpr=1&quality=100&sign=ac6c4233&sv=1"/>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FaWQrXGeZbosNI4BIijkW%252Fimage.png%3Falt%3Dmedia%26token%3D441b5427-d55a-48fc-95c6-ad00b7f8fe5b&width=768&dpr=1&quality=100&sign=98ac54a9&sv=1"/>
        <p><strong>Protocolo:</strong> Gere protocolos para seus atendimento. Para saber mais, leia a seção 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/admnistrador/protocolos"> Protocolos</Link> da central de ajuda.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FcjorfZEoOltg1VPjXpul%252Fimage.png%3Falt%3Dmedia%26token%3D00e1206b-ac83-4437-85a2-729fd0f9e473&width=768&dpr=1&quality=100&sign=adc158b8&sv=1"/>
        <p><strong>Avaliação:</strong> Você pode enviar uma pesquisa de avaliação do atendimento. Para mais informações, leia a seção 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/admnistrador/avaliacoes"> Avaliações</Link> da central de ajuda.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FXB0Tm14g01PpxgckZDtW%252Fimage.png%3Falt%3Dmedia%26token%3Dd9d3ade8-07f1-4fc7-b1a6-efcf9c27140b&width=768&dpr=1&quality=100&sign=2fc266cd&sv=1"/>
        <p><strong>Nota:</strong> Registre uma nota para controle dos atendimentos. Para saber mais, leia a seção Notas da central de ajuda.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FDMSKaG48tA5SjUGwkL0C%252Fimage.png%3Falt%3Dmedia%26token%3D22f4b05b-dc64-44f2-8d3b-84026bc75e45&width=768&dpr=1&quality=100&sign=18b40556&sv=1"/>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FWmqAY4WJmgyXTPOCf8zn%252Fimage.png%3Falt%3Dmedia%26token%3Df148aef9-d83b-43ac-aac9-9f6f93b7fadd&width=768&dpr=1&quality=100&sign=850a22fb&sv=1"/>
        <p><strong>Extrair conversas:</strong> Ao opção permite que você baixe as conversas do atendimento em um arquivo no formato PDF.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252F8mhzbFHdhopUjUfqZJt8%252Fimage.png%3Falt%3Dmedia%26token%3D9e612516-60be-40d2-86f7-64ef83ed74ea&width=768&dpr=1&quality=100&sign=137470cd&sv=1"/>
        <p><strong>Typebot:</strong> Ative ou desative a interação com Typebot para o atendimento. Essa opção estará disponível caso a interação esteja ativa nas configurações gerais. Para saber mais, leia a seção 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/admniistrador/configuracoes"> Configurações </Link>e Canais da central de ajuda.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FqPbfPwUhSnsHzvukKMkq%252Fimage.png%3Falt%3Dmedia%26token%3Dadffe50b-6730-4037-a1b8-6259767e42b2&width=768&dpr=1&quality=100&sign=fff72b64&sv=1"/>
        <p><strong>ChatGpt:</strong> Ative ou desative a interação com ChatGpt para o atendimento. Essa opção estará disponível caso a interação esteja ativa nas configurações gerais. Para saber mais, leia a seção 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/administrador/configuracoes"> Configurações </Link>e Canais da central de ajuda.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FAX2vOXAUlbUWpmYSdfSc%252Fimage.png%3Falt%3Dmedia%26token%3Db1f4d10f-cf87-4230-a219-e7dd321ec71e&width=768&dpr=1&quality=100&sign=e31f1e71&sv=1"/>
        <p><strong>N8N:</strong> Ative ou desative a interação com N8N para o atendimento. Essa opção estará disponível caso a interação esteja ativa nas configurações gerais. Para saber mais, leia a seção 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/administrador/configuracoes"> Configurações </Link>e Canais da central de ajuda.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FdsufrqnnhgXM3oE8MPtO%252Fimage.png%3Falt%3Dmedia%26token%3D302daaf6-004a-47d0-94a1-e9a9fd1d5f47&width=768&dpr=1&quality=100&sign=28d5f6ca&sv=1"/>
        <p><strong>Dify:</strong> Ative ou desative a interação com Dify para o atendimento. Essa opção estará disponível caso a interação esteja ativa nas configurações gerais. Para saber mais, leia a seção 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/administrador/configuracoes"> Configurações </Link>e Canais da central de ajuda.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252Fp2cknfRZkruHOgfm2zpf%252Fimage.png%3Falt%3Dmedia%26token%3Db7b2f126-c205-444e-a58d-5d4fbb930c0b&width=768&dpr=1&quality=100&sign=dfc834ef&sv=1"/>
        <p><strong>Kanban:</strong> Selecione um Kanban previamente cadastrado para um melhor Controle Visual de Atendimentos. Leia a seção 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/usuarios/kanban">Kanban</Link> da central de ajuda. Para saber mais, leia a seção Notas da central de ajuda.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FD44KnZ6tLx0USb7W9fgW%252Fimage.png%3Falt%3Dmedia%26token%3D9f20b3d3-1a6d-4d9c-9855-b115e6d87d88&width=768&dpr=1&quality=100&sign=572f2a94&sv=1"/>
        <p><strong>Bloquear:</strong> Ativando essa opção não serão mais recebidas mensagens desse contato dentro da plataforma.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FMoqhQBYEH7aiHhDc5Mo2%252Fimage.png%3Falt%3Dmedia%26token%3Dc0809fcf-97cc-4cf2-bcaf-76ca4682f64b&width=768&dpr=1&quality=100&sign=c0acb9ef&sv=1"/>
        <p><strong>Etiqueta:</strong> Atribua uma etiqueta para auxiliar na classificação do atendimento. Clique no símbolo de + e selecionar qual etiqueta melhor se encaixa. Para saber mais, leia a seção Etiquetas da central de ajuda.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FnNS782tnLWIGixcwYcOc%252Fimage.png%3Falt%3Dmedia%26token%3Deab807ef-5e5b-426b-b1a0-9732557bd985&width=768&dpr=1&quality=100&sign=7c495340&sv=1"/>
        <p><strong>Carteira:</strong> A carteira serve para que possa atribuir um usuário fixo para o atendimento de um contato. Ou seja, caso tenha um cliente recorrente você consegue alocar todos os atendimentos dele para o usuário que melhor atenda o seu perfil. Para isso basta clicar no símbolo de + e selecionar no usuário.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FRoRR84KmuJ5ffDS16GgZ%252Fimage.png%3Falt%3Dmedia%26token%3D307b00c5-7dc6-4599-9401-4fa6bacdd37a&width=768&dpr=1&quality=100&sign=cdadee61&sv=1"/>
        <p><strong>Mensagens Agendadas:</strong> Você pode visualizar quantas mensagens foram agendadas através do botão de agendamento. Além disso é possível ver o conteúdo, data e horário de envio. Para saber mais, leia a seção 
            <Link style={{ color: "#9bc1db", textDecoration: "none" }} to="/administrador/agendamentos"> Agendamentos </Link> da central de ajuda.
        </p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FBjOEuv1rlMQmmGjpRClw%252Fimage.png%3Falt%3Dmedia%26token%3D6752e643-16b2-4222-8c42-b24f9d2dfb8d&width=768&dpr=1&quality=100&sign=2ba655d7&sv=1"/>
        <br/>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252F51pJf0IkeiaeN21uDayE%252Fimage.png%3Falt%3Dmedia%26token%3D2f3075fd-2424-4fa9-ac75-f7d548401673&width=768&dpr=1&quality=100&sign=61201334&sv=1"/>
        <br/>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252F9LyT3wydmbRCGnJ3zSkF%252Fimage.png%3Falt%3Dmedia%26token%3Da2a470ff-a9b3-4461-b449-a849557105f6&width=768&dpr=1&quality=100&sign=a4473437&sv=1"/>

      </Box>

    </Box>
  );
}