import { Accordion, AccordionSummary, AccordionDetails, Drawer } from '@mui/material';
import data from "./data.json";
import { Link } from 'react-router-dom';

function accordionLoop(json) {
    return (

        json.map((item) => {
            return (
                <Accordion disableGutters
                    sx={{
                        backgroundColor: "#202a45",
                        color: "#ffffff",
                        boxShadow: "none",
                        fontWeight: "500",
                    }}
                >
                    <AccordionSummary><Link style={{color: "#ffffff", textDecoration: "none"}} to={item.slug}>{item.name}</Link></AccordionSummary>

                    {item.content.map((value) => {
                        return (
                            <AccordionDetails
                                sx={{
                                    color: "#afafaf",
                                    textIndent: "10%",
                                    fontWeight: "100"
                                }}
                            ><Link style={{color: "#afafaf", textDecoration: "none"}} to={item.slug + value.slug}>{value.name}</Link></AccordionDetails>
                        );
                    })}
                </Accordion>
            );
        })

    )
}

function Sidebar() {
    return (
        <Drawer
            PaperProps={{
                sx: {
                    backgroundColor: "#202a45",
                    width: "15%",
                    paddingTop: "5%"
                }
            }}
            variant="permanent"
            anchor="left"
        >

            {accordionLoop(data)}

        </Drawer>
    );
}

export default Sidebar;