import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from './components/Navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
import { Introducao } from './pages/Introducao';
import { RequisitosDeUso } from './pages/RequisitosDeUso';
import { DuvidasFrequentes } from './pages/DuvidasFrequentes';
import { Usuarios } from "./pages/Usuarios";
import { Supervisor } from "./pages/Supervisor";
import { Administrador } from "./pages/Administrador";
import { SuperAdmin } from "./pages/SuperAdmin";
import { BarraDeFerramentas } from "./pages/BarraDeFerramentas";
import { Dashboard } from "./pages/Dashboard";
import { Atendimentos } from "./pages/Atendimentos";
import { AtendimentosGerais } from "./pages/AtendimentosGerais";

function App() {
  return (
    <html style={{height: "100vh", backgroundColor: "#363f57"}}>

      <Navbar/>

      <Sidebar/>

      <Routes>
        <Route path="/introducao" element={<Introducao/>}/>
        <Route path="/requisitos-de-uso" element={<RequisitosDeUso/>}/>
        <Route path="/duvidas-frequentes" element={<DuvidasFrequentes/>}/>
        <Route path="/usuarios" element={<Usuarios/>}/>
        <Route path="/supervisor" element={<Supervisor/>}/>
        <Route path="/admnistrador" element={<Administrador/>}/>
        <Route path="/super-admin" element={<SuperAdmin/>}/>
        <Route path="/:id/barra-de-ferramentas" element={<BarraDeFerramentas/>}/>
        <Route path="/:id/dashboard" element={<Dashboard/>}/>
        <Route path="/:id/atendimentos" element={<Atendimentos/>}/>
        <Route path="/:id/atendimentos-gerais" element={<AtendimentosGerais/>}/>
      </Routes>

    </html>
  );
}

export default App;
