import { Box } from "@mui/material"

export function Atendimentos() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "100%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Atendimentos</h1>
        <p>Painel de Atendimentos</p>
        <p>Essa é uma das seções mais importantes da plataforma. Para acessá-la, clique no ícone de Atendimentos no menu lateral do seu painel.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2Fcontent.gitbook.com%2Fcontent%2FM5B652UsmGn3Q7h9Du1t%2Fblobs%2F979JMd4m8ThpJIIXwVKT%2Fimage.png&width=768&dpr=1&quality=100&sign=cbab647f&sv=1"/>
        <p>Você verá a seguinte tela:</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FrBsuBb87kSZIMCmvGNyK%252Fimage.png%3Falt%3Dmedia%26token%3D831a349f-e72b-4f1e-a3d7-bb09bcb35884&width=768&dpr=1&quality=100&sign=2265ec73&sv=1"/>


      </Box>

    </Box>
  );
}