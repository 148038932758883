import { Box } from "@mui/material"

export function BarraDeFerramentas() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "115%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Barra de Ferramentas</h1>
        <p>Barra de ferramentas</p>
        <p>A barra de ferramentas encontra-se no menu superior a direita.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FzYEoq95zT6NnT8MPED66%252Fimage.png%3Falt%3Dmedia%26token%3D48b84fa2-0fc0-496e-9ec4-de86bbdbb7c8&width=768&dpr=1&quality=100&sign=45038701&sv=1"/>
        <p><strong>Notificações internas do sistema:</strong> comunicados criados pelo superadmin.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FM1nZWEDDkLtQH46DvWUz%252Fimage.png%3Falt%3Dmedia%26token%3D9da1a595-6e64-4bbe-9a53-c023b653dffe&width=768&dpr=1&quality=100&sign=29572585&sv=1"/>
        <p><strong>Conversas avulsas:</strong> opção para enviar mensagens para qualquer número, mesmo que não seja um contato da plataforma.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FJk8WgtEtlAnK36XRqQNK%252Fimage.png%3Falt%3Dmedia%26token%3D861acf3f-32fc-4911-83c7-9d088b75de0a&width=768&dpr=1&quality=100&sign=9f138294&sv=1"/>
        <p><strong>Notificações de Mensagens e Chat Interno:</strong> visualização de mensagens recebidas no atendimento e chat interno.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FPQ3a7qgSQ2aunysT4EZE%252Fimage.png%3Falt%3Dmedia%26token%3D38196c2a-4fd6-401b-984c-c2f0664d7a2d&width=768&dpr=1&quality=100&sign=76aced06&sv=1"/>
        <p><strong>Edição de Perfil:</strong> opção para editar dados do perfil do usuário.</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FhIs72RqIX0L9pORU04dO%252Fimage.png%3Falt%3Dmedia%26token%3D6090709c-5e75-437a-bb94-15690c637f7a&width=768&dpr=1&quality=100&sign=6887019c&sv=1"/>

      </Box>

    </Box>
  );
}