import { Box } from "@mui/material"

export function Introducao() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        widht: "100%",
        height: "100%",
        backgroundColor: "#363f57",
        marginTop: "5%",
        marginLeft: "15%",
        justifyContent: "center"
      }}>

      <Box
        sx={{
          width: "50%",
          marginRight: "25%",
          color: "#e9e9e9"
        }}>

        <h1>Introdução</h1>
        <p>Plataforma de Multiatendimento</p>
        <img src="https://zpro.passaportezdg.com.br/~gitbook/image?url=https%3A%2F%2F3541975055-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FM5B652UsmGn3Q7h9Du1t%252Fuploads%252FOZzRrvvCpvVFLuts4Gq1%252Fzpro.png%3Falt%3Dmedia%26token%3Daa36d7c3-fa70-41cc-8b8b-9a8339f25fbd&width=768&dpr=1&quality=100&sign=2641a791&sv=1" />
        <p>A Plataforma foi projetada não apenas para uma ótima experiência do cliente, mas também para melhorar a produtividade de sua equipe de suporte.</p>
        <p>A conexão primária da Plataforma é a API Oficial do WhatsApp (WABA).</p>
        <p>Essa plataforma foi criada com o objetivo de oferecer soluções de autoatendimento 24h através de chatbots, gerenciamento de atendimentos com atendentes humanos e disparos de mensagens em massa via WhatsApp para o seu público-alvo.</p>
        <p>Você pode integrar sua conta com vários contatos ao mesmo tempo, através de um painel que integra o seu WhatsApp de uma forma muito mais eficiente.</p>
        <p>É possível visualizar todos os seus atendimentos em um único lugar! Isso ajuda a reduzir o tempo de resposta e melhorar a qualidade das tratativas. Você também consegue gerenciar suas conversas e colaborar com sua equipe, a qualquer hora e de qualquer lugar.</p>
        <p>Neste guia do usuário, explicaremos todos os recursos, funcionalidades, modos de operação e passo a passo para usar facilmente a plataforma.</p>
      </Box>

    </Box>
  );
}